export const production = [
  {
    type: 'ad',
    img: new URL('../assets/ads/v_goose.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/v_goose.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.refarm.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '11:20',
    desc: 'Lean farming: підвищення ефективності виробництва та ефективності процесів',
    speakerName: 'Андрій Дмитрієв',
    speakerDesc: 'Консультант LEAN INSTITUTE UKRAINE',
    speakerImg: new URL('../assets/speakers/dmytriev.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/dmytriev_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:00',
    desc: 'Аграрно-продовольчі ринки: куди продавати і куди прямувати.',
    speakerName: 'Ольга Трофімцева',
    speakerDesc: 'доктор аграрних наук, екс-посол з особливих доручень МЗС, екс-заступник Міністра АПК',
    speakerImg: new URL('../assets/speakers/trofimceva.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:00',
    desc: 'Водна меліорація як джерело стабільності та прибутків, яке ми недооцінюємо',
    speakerName: 'Олеся Точицька',
    speakerDesc: 'Заступник з земельних питань та розвитку господарства',
    speakerImg: new URL('../assets/speakers/tochinskya.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/ribalko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:40',
    desc: 'Стрімкий розвиток амарантової галузі. Україна на шляху до світового лідерства',
    speakerName: 'Олександр Дуда',
    speakerDesc: 'Голова ГО «Асоціація виробників амаранту та амарантової продукції» ',
    speakerImg: new URL('../assets/speakers/duda.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/duda_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:20',
    desc: 'Бізнес на курях: з чого розпочати та чи прибутково? Досвід Тетіїв ферми',
    speakerName: 'Андрій Соломонюк',
    speakerDesc: 'Власник "Тетіїв Ферма"',
    speakerImg: new URL('../assets/speakers/solomonyk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/solomonyk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:00',
    desc: 'Вирощування лікарських рослин: прибуткова альтернатива класичним культурам',
    speakerName: 'Приведенюк Назар',
    speakerDesc: 'кандидат сільськогосподарських наук завідувач відділом технології вирощування лікарських рослин Дослідної станції лікарських рослин ІАП НААН',
    speakerImg: new URL('../assets/speakers/privednyk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/privednyk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:40',
    desc: 'Кооперація та виробництво: досвід Фрутбанк',
    speakerName: 'Павло Дигдало',
    speakerDesc: 'КП "Фрут Банк" Мостиської МР Львівської',
    speakerImg: new URL('../assets/speakers/digdalo.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/digdalo_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:40',
    desc: 'Технічні коноплі — актуальні питання вирощування та переробки',
    speakerName: 'Сергій Ткаченко',
    speakerDesc: 'директор Інституту луб’яних культур НААН України',
    speakerImg: new URL('../assets/speakers/tkachenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/layko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:20',
    desc: 'Логістика та експорт',
    speakerName: 'Сергій Ткачук',
    speakerDesc: 'керівник комерційного відділу',
    speakerImg: new URL('../assets/speakers/tkachuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/tkachuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:00',
    desc: 'Карбонфармінг',
    speakerName: 'Віталій Андрющенко',
    speakerDesc: 'Виконавчий директор ПОСП імені Івана Франка',
    speakerImg: new URL('../assets/speakers/andrushenkoo.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '16:20',
    desc: 'Перспективи вирощування технічної та медичної коноплі в Україні',
    titleName: 'Панельна дискусія',
    speakers: [
      {
        speakerName: 'Лайко Ірина',
        speakerDesc: 'Завідувачка відділу селекції та насінництва конопель Інституту луб’яних культур, доктор сільськогосподарських наук',
        speakerImg: new URL('../assets/speakers/layko.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/layko_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Андрій Алечко',
        speakerDesc: 'Засновник компанії',
        speakerImg: new URL('../assets/speakers/alechko.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/alechko_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Сергій Ткаченко',
        speakerDesc: 'директор Інституту луб’яних культур НААН України Технічні коноплі — актуальні питання вирощування та переробки',
        speakerImg: new URL('../assets/speakers/tkachenko.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/layko_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Геннадій Шабас',
        speakerDesc: 'голова Української асоціації медичного канабісу',
        speakerImg: new URL('../assets/speakers/shabas.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/shabas_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'reportMulti',
    time: '12:00',
    desc: 'Досвід ВІЛІЯ-АГРО у вирощуванні продукції згідно європейських стандартів якості',
    speakers: [
      {
        speakerName: 'Ярослав Мазуренко',
        speakerDesc: 'Керівник департаменту аграрного виробництва',
        speakerImg: new URL('../assets/speakers/mazurenko.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/mazurenko_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Євген Дудка',
        speakerDesc: 'Засновник компанії',
        speakerImg: new URL('../assets/speakers/dudka.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/mazurenko_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '17:30',
    title: 'ВЕЧІРНЯ ПРОГРАМА',
  },
];
