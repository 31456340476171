import { business } from './business';
// import { business2 } from './business-2';
// import { masterclass } from './masterclass';
// import { masterclass2 } from './masterclass-2';
import { precisionAgriculture } from './precision-agriculture';
import { precisionAgriculture2 } from './precision-agriculture-2';
import { production } from './production';
// import { production2 } from './production-2';
import { technology } from './technology';
import { technology2 } from './technology-2';

// const removeSpacesInTime = (arr) =>

const sortByTime = (arr) =>
  arr.sort(({ time: a }, { time: b }) => {
    if (!a || !b) return 0;

    const startA = a
      .replace(/&nbsp;/g, '')
      .split('-')
      .at(0)
      .trim();
    const startB = b
      .replace(/&nbsp;/g, '')
      .split('-')
      .at(0)
      .trim();

    return new Date(`2023-01-01 ${startA}`).getTime() - new Date(`2023-01-01 ${startB}`).getTime();
  });

export const programs = {
  business: sortByTime(business),
  // masterclass: sortByTime(masterclass),
  production: sortByTime(production),
  technology: sortByTime(technology),
  'precision-agriculture': sortByTime(precisionAgriculture),
  // business2: sortByTime(business2),
  // masterclass2: sortByTime(masterclass2),
  // production2: sortByTime(production2),
  technology2: sortByTime(technology2),
  'precision-agriculture2': sortByTime(precisionAgriculture2),
};
