export const business = [
  {
    type: 'ad',
    img: new URL('../assets/ads/b_zemlerob_sponsor_1.gif', import.meta.url),
    height: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '14:40',
    desc: 'Як інвестувати в агро на фондовому ринку',
    speakerName: 'Віталій Федоришен',
    speakerDesc: 'інвестиційний експерт',
    speakerImg: new URL('../assets/speakers/fedorishin.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/fedorishin_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:20',
    desc: 'Рожеві окуляри агробізнесу: як аграрію перестати дурити самого себе',
    speakerName: 'Станіслав Шум',
    speakerDesc: 'CEO Aggeek',
    speakerImg: new URL('../assets/speakers/shum.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/shum_aggeek_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:20',
    desc: 'Розподіл відповідальності і повноважень як відповідь на виклики 2024 року',
    speakerName: 'Ілля Новіков',
    speakerDesc: 'консультант і ментор Дія-Бізнеc, консультант ЕБРР, спікер TEDx',
    speakerImg: new URL('../assets/speakers/Novikov.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/Novikov_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:20',
    desc: 'Найбільші ризики АгроБізнесу 2024: податкові перевірки та дострокове розірвання договорів оренди. Як захищатися',
    speakerName: 'Анна Кухарчук',
    speakerDesc: 'керуючий партнер компанії, адвокат ',
    speakerImg: new URL('../assets/speakers/annakux.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/annakux_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:40',
    desc: 'Грантова підтримка агробізнесу',
    speakerName: 'Григорій Шамрицький',
    speakerDesc: 'ФГ «Моя Земля 2015»,ТОВ «Полтава Агро»',
    speakerImg: new URL('../assets/speakers/shamrickiy.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:20',
    desc: 'Ефективність чи мета? Що ж то таке - Feodal.FMS?',
    speakerName: 'Андрій Дем`янович',
    speakerDesc: 'СЕО Feodal',
    speakerImg: new URL('../assets/speakers/demianovich.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/demianovich_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:00',
    desc: 'Пошук спеціалістів, навчання та мотивація команди.',
    speakerName: 'Сергій Макуха',
    speakerDesc: 'ФГ "ІНТЕР-АГРО-БАЗА"',
    speakerImg: new URL('../assets/speakers/makuha.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:40',
    desc: 'Як аграріям допомагає благодійний фонд "Жнива перемоги"',
    speakerName: 'Дмитро Соломчук',
    speakerDesc:
      'Голова наглядової ради благодійного фонду «Жнива Перемоги», Народний депутат України, член парламентського комітету з питань аграрної та земельної політики.',
    speakerImg: new URL('../assets/speakers/solomchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/solomchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:00',
    desc: 'Як вижити в ці складні часи без фінансової подушки',
    speakerName: 'Марія Завгородня',
    speakerDesc: 'СФГ "Анкор"',
    speakerImg: new URL('../assets/speakers/zavgorodnya.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:00',
    desc: 'Як запустити і розвивати фг у війну?',
    speakerName: 'Оксана Лукіянчук',
    speakerDesc: 'ФГ "Клаптик Степу"',
    speakerImg: new URL('../assets/speakers/lukiyanchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/lukiyanchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:20',
    desc: 'Як максимально заощадити при покупці С/Г техніки?',
    speakerName: 'Шалденко Олег',
    speakerDesc: 'Засновник ТОВ "ТриДаАгро"',
    speakerImg: new URL('../assets/speakers/shaldenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/shaldenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:00',
    desc: 'Від нуля до мільйона: стратегії досягнення фінансового успіху на одному гектарі в умовах війни',
    speakerName: 'Михайло Кобченко',
    speakerDesc: 'Комерційний директор ТОВ "АПК "Докучаєвські чорноземи"',
    speakerImg: new URL('../assets/speakers/kobchenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kobchenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:00',
    desc: 'Хто такий ВЛАСНИК агро-бізнесу?',
    speakerName: 'Андрій Капріца',
    speakerDesc: 'Власник ФГ "ФЛОРА А.А"',
    speakerImg: new URL('../assets/speakers/caprica.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/caprica_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '17:30',
    title: 'ВЕЧІРНЯ ПРОГРАМА',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
];
