export const technology2 = [
  {
    type: 'ad',
    img: new URL('../assets/ads/technology_greco_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/technology_greco_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://greco.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    disable: false,
    type: 'report',
    time: '10:40',
    desc: 'Як впровадити технологію Альбрехта-Кінсі в Україні',
    speakerName: 'Віктор Рибалко',
    speakerDesc: 'Директор ФГ "АДЕЛАЇДА"',
    speakerImg: new URL('../assets/speakers/ribalko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/ribalko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '11:00',
    desc: 'Інформативний аналіз грунту. Як зробити та самостійно прочитати результати досліджень',
    speakerName: 'Валерія Ващенко',
    speakerDesc: 'Директорка з інновацій компанії "ZEMLEROB"',
    speakerImg: new URL('../assets/speakers/vaschenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '10:00',
    desc: 'Збалансування поживних елементів у грунті за ЄКО, як альтернатива класичній системі живлення рослин, що базується на виносі елементів та на кислотності грунту',
    speakers: [
      {
        speakerName: 'York Bayer',
        speakerDesc: 'консультант системи збалансування ґрунту за ЄКО Проф. Альбрехта',
        speakerImg: new URL('../assets/speakers/YorkBayer.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Роман Гринишин',
        speakerDesc: 'директор і засновник компанії «Тревелайт MICE&Travel»',
        speakerImg: new URL('../assets/speakers/grinshin.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/grinshin_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'report',
    time: '12:00',
    desc: 'Жито в польовій сівозміні - міфічна ніша чи реальність?',
    speakerName: 'Олег Фурманець',
    speakerDesc: ' керівник науково-дослідного відділу, ТОВ «Контінентал Фармерз Груп»',
    speakerImg: new URL('../assets/speakers/furmanec.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/furmanec_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:00',
    desc: 'Прийняття управлінських рішень на основі агрономічних дослідів',
    speakerName: 'Павло Янін',
    speakerDesc: 'Епіцентр Агро',
    speakerImg: new URL('../assets/speakers/yanin.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/yanin_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:30',
    desc: 'Живлення рослин. Інноваційні агротехнологічні рішення',
    speakerName: 'Костянтин Хоменко',
    speakerDesc: 'Директор компанії Агротерра',
    speakerImg: new URL('../assets/speakers/homenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/homenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:30',
    desc: 'Як з хорошого попередника зробити рентабельну культуру',
    speakerName: 'Сергій Богомаз',
    speakerDesc: 'Експерт з захисту рослин',
    speakerImg: new URL('../assets/speakers/bogomaz.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '11:30',
    desc: 'Давид проти Голіафа або яяк протистояти закордонним компаніям на прикладі МНАГОР',
    speakerName: 'Михайло Нагорняк',
    speakerDesc: 'ПП "Мнагор"',
    speakerImg: new URL('../assets/speakers/nagornyak.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/nagornyak_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '09:00',
    title: 'РЕЄСТРАЦІЯ',
  },
  {
    type: 'break',
    time: '09:40',
    title: 'Відкриття Re:farm 2024. Презентація навчального центру BAZA',
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '16:00',
    title: 'ЗАКРИТТЯ КОНФЕРЕНЦІЇ',
  },
];
