export const precisionAgriculture = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    // disable: true,
    type: 'report',
    time: '11:00',
    desc: 'База про точне землеробство',
    speakerName: 'Валерія Ващенко',
    speakerDesc: 'Директорка з інновацій компанії "ZEMLEROB"',
    speakerImg: new URL('../assets/speakers/vaschenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:20',
    desc: 'Результати диференційованого посіву кукурудзи. Що зрозуміли у 2023 та що плануємо у 2024',
    speakerName: 'Павло Янін',
    speakerDesc: 'Епіцентр Агро',
    speakerImg: new URL('../assets/speakers/yanin.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/yanin_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:20',
    desc: 'Впровадження точного землеробства',
    speakerName: 'Сергій Бовтачук',
    speakerDesc: 'Фахівець з точного землеробства',
    speakerImg: new URL('../assets/speakers/bovtachhuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/bovtachhuk_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '15:40',
    desc: 'Чекап готовності та покрокова стратегія впровадження ТЗ. Кейс Ель Гаучо',
    speakerName: 'Михайло Волченко',
    speakerDesc: 'ПП НВАП "Ель Гаучо"',
    speakerImg: new URL('../assets/speakers/volchenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/volchenko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '11:40',
    desc: 'Еволюція підходів до формування карт завдань під час закладки виробничих дослідів», або карти завдання VRI , чому саме так і не інакше.',
    speakerName: 'Євген Корнієнко',
    speakerDesc: 'начальник відділу агрономічного моніторингу полів "ContinentalFarmers Group"',
    speakerImg: new URL('../assets/speakers/kornienko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kornienko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:00',
    desc: 'Система живлення на основі зон продуктивності',
    speakerName: 'Олександр Мустіпан',
    speakerDesc: 'Керівник відділу діджиталізації',
    speakerImg: new URL('../assets/speakers/mustipan.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/mustipan_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:00',
    desc: 'Диференційоване внесення добрив та посів - підходи та ефективність',
    speakerName: 'Валентин Ємець',
    speakerDesc: 'незалежний консультант - ІнАгрі',
    speakerImg: new URL('../assets/speakers/emec.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:20',
    desc: 'Супутникові знімки, NDVI та інші можливості космічних технологій',
    speakerName: 'Сергій Микитченко',
    speakerDesc: '...',
    speakerImg: new URL('../assets/speakers/mikitchenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '15:00',
    desc: 'Системи картування врожайності. Як налаштувати збір даних у себе на підприємстві',
    speakerName: 'Мирослав Гуранський',
    speakerDesc: '...',
    speakerImg: new URL('../assets/speakers/guransky.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '16:00',
    desc: 'Як найняти або навчити спеціалістів по ТЗ для свого господарства',
    speakerName: 'Геннадій Гадзовський',
    speakerDesc: 'Власник СТОВ "Васюти"',
    speakerImg: new URL('../assets/speakers/gadzovsky.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '16:40',
    desc: 'FUCK-UP EVENING: чесно (смішно) про конфузи в точному землеробстві',
    speakers: [
      {
        speakerName: 'Валерія Ващенко',
        speakerDesc: 'МОДЕРАТОР',
        speakerImg: new URL('../assets/speakers/vaschenko.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Андрій Капріца',
        speakerDesc: 'Власник ФГ "ФЛОРА А.А"',
        speakerImg: new URL('../assets/speakers/caprica.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/caprica_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Володимир Присяжненко',
        speakerDesc: 'Власник ТОВ "АГРО-КЛАСІК"',
        speakerImg: new URL('../assets/speakers/prisyagnenko.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Олексій Місюра',
        speakerDesc: 'керівник відділу R&D ІМК',
        speakerImg: new URL('../assets/speakers/micura.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '17:30',
    title: 'ВЕЧІРНЯ ПРОГРАМА',
  },
];
