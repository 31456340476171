export const precisionAgriculture2 = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    disable: false,
    type: 'report',
    time: '12:00',
    desc: 'Перетворюємо інструменти точного землеробства в реальні гроші. Досвід найбільшого фермера Чехії',
    speakerName: 'Josef Chára',
    speakerDesc: 'Statek Chyse (фермер, Чехія)',
    speakerImg: new URL('../assets/speakers/JosefChara.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/JosefChara_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:30',
    desc: 'Цифрові рішення для ефективного планування технічного обслуговування: як не пропускати ремонти',
    speakerName: 'Іван Бурʼянов',
    speakerDesc: 'студія Аграрних Систем',
    speakerImg: new URL('../assets/speakers/buryanov.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/buryanov_logo.png?as=webp', import.meta.url),
  },
  {
    disable: false,
    type: 'report',
    time: '14:00',
    desc: 'Аналіз даних агрооперацій за допомогою цифрової платформи Climate FieldView',
    speakerName: 'Бица Роман',
    speakerDesc: 'Провідний фахівець технологічного супроводу продукту на англійській Digital Farming Specialist',
    speakerImg: new URL('../assets/speakers/bica.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/empty.png?as=webp', import.meta.url),
  },
  {
    disable: false,
    type: 'report',
    time: '11:30',
    desc: 'Як отримати користь від кожного долара, вкладеного у точне землеробство',
    speakerName: 'Олексій Агєєв',
    speakerDesc: ' інженер-мехатронік систем точного землеробства, SmartFarming',
    speakerImg: new URL('../assets/speakers/ageev.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/ageev_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '14:30',
    desc: 'Питання - відповідь',
    speakerName: 'Валерія Ващенко',
    speakerDesc: 'Директорка з інновацій компанії "ZEMLEROB"',
    speakerImg: new URL('../assets/speakers/vaschenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '13:00',
    title: 'ОБІД',
  },
  {
    type: 'break',
    time: '10:00',
    title: 'Precision Planting Workshop',
  },
  {
    type: 'break',
    time: '16:00',
    title: 'ЗАКРИТТЯ КОНФЕРЕНЦІЇ',
  },
];
